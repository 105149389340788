import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 5×2\\@90% 1RM`}</p>
    <p>{`Single Arm DB Row 5×2/arm`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`20-S2OH (95/65)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      